import { useState } from 'react';
import Button from '../../ui/Button/Button';
import classes from './Onboarding.module.css'
import Earn from '../../pages/Earn/Earn';
import Home from '../../pages/Home/Home';
import Boost from '../../pages/Boost/Boost';
import { useTranslation } from 'react-i18next';
import Menu from '../../ui/Menu/Menu';

import home from '../../../assets/svgs/menu-home.svg'
import boost from '../../../assets/svgs/menu-boost.svg'
import earn from '../../../assets/svgs/menu-earn.svg'
import homeActive from '../../../assets/svgs/menu-home-active.svg'
import boostActive from '../../../assets/svgs/menu-boost-active.svg'
import earnActive from '../../../assets/svgs/menu-earn-active.svg'
import MenuItem from '../../ui/MenuItem/MenuItem';

const positions = [
  {bottom: '136px'},
  {bottom: '200px'},
  {bottom: '235px', left: '30px'},
  {bottom: '235px', right: '30px'},
  {bottom: '130px', left: '30px'},
  {bottom: '235px'},
  {bottom: '235px', left: '30px'},
]


const index = [
  0,
  0,
  0,
  1,
  3,
  0,
  0
]


const Onboarding = ({ token, content, setContent, setActiveTab, onEnd, setActiveIndex }) => {
  const { t } = useTranslation();
  
  const tabs = [
    <></>,
    <Home token={token}/>,
    <Home token={token}/>,
    <Boost token={token}/>,
    <Earn token={token}/>,
    <Home token={token}/>
  ]

  const text = [
    <p>{t('onb.a')}<br/>{t('onb.b')}<br/>{t('onb.c')}</p>,
    <p>{t('onb.d')}<br/>{t('onb.e')} <br/>{t('onb.f')} </p>,
    <p>{t('onb.g')}<br/>{t('onb.h')} <br/> {t('onb.i')}</p>,
    <p>{t('onb.j')} <br/> {t('onb.k')}<br/> {t('onb.l')}<br/> {t('onb.m')}<br/>{t('onb.n')}</p>,
    <p>{t('onb.o')} <br/>{t('onb.p')} </p>,
    <p>{t('onb.q')} <br/>{t('onb.r')} <br/>{t('onb.s')} <br/>{t('onb.t')} <br/>{t('onb.u')}</p>,
    <p>{t('onb.v')} <br/>{t('onb.w')} <br/>{t('onb.x')}</p>
  ]

  const clickHandler = () => {
    if (content > 0) {
      setContent((prev) => prev + 1)
      setActiveTab(tabs[content])
      setActiveIndex(index[content])
    }
    if (content >= 6) {
      onEnd()
      console.log('end')
    }
  }

  return (
    <div className={classes.Onboarding} onClick={clickHandler}>
      <div className={classes.modalContent} style={positions[content]}>
        {text[content]}
      </div>
      {content === 1 &&
          <div className={classes.button}>{t('onb.go')}</div>
        }
      <div className={classes.abs}>
        <div className={classes.item}>
          <div className={classes.circle} style={{opacity: (content === 2 || content === 3 || content === 6) ? '100%' : '0%'}}>
            <img src={homeActive} alt='menu' className={classes.image}/>
          </div>
        </div>
        <div className={classes.item}>
          <div className={classes.circle} style={{opacity: (content === 4) ? '100%' : '0%'}}>
            <img src={boostActive} alt='menu' className={classes.image}/>
          </div>
        </div>
        <div className={classes.item} style={{opacity: '0%'}}>
          <div className={classes.circle}>
            <img src={homeActive} alt='menu' className={classes.image}/>
          </div>
        </div>
        <div className={classes.item}>
          <div className={classes.circle} style={{opacity: (content === 5) ? '100%' : '0%'}}>
            <img src={earnActive} alt='menu' className={classes.image}/>
          </div>
        </div>
        <div className={classes.item}>
          <div className={classes.circle} style={{opacity: '0%'}}>
            <img src={homeActive} alt='menu' className={classes.image}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Onboarding;