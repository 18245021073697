import axios from "axios";
import { decode } from "./userApi";
import { path } from "./path";

export const getTop = async (league, token) => {
  try {
    const response = await axios.get(
      `${path}/leagues/top_users/${league}/${decode(token)}`, 
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка получения рейтинга', err);
    return null;
  }
};

export const getWeekly = async (league, token) => {
  try {
    const response = await axios.get(
      `${path}/leagues/top_users_weekly/${league}/${decode(token)}`, 
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка получения рейтинга', err);
    return null;
  }
};