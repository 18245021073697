import axios from "axios";
import { decode } from "./userApi";
import { path } from "./path";

export const getTimeToRefill = async (token) => {
  try {
    const response = await axios.get(
      `${path}/energy/refill-time/${decode(token)}`, 
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    
    return response.data;

  } catch (err) {
    console.error('Ошибка при полчучении времени восполнения энергии', err);
    return null;
  }
};

export const refillEnergy = async (token) => {
  try {
    const response = await axios.post(
      `${path}/energy/restore/${decode(token)}`, 
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    
    return response.data;

  } catch (err) {
    console.error('Ошибка при восполнении энергии', err);
    return null;
  }
};

export const upgradeMultitap = async (token) => {
  try {
    const response = await axios.post(
      `${path}/boosts/multi-tap/${decode(token)}`, 
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    
    return response.data;

  } catch (err) {
    console.error('Ошибка при повышении уровня мультитап', err);
    return null;
  }
};

export const upgradeEnergy = async (token) => {
  try {
    const response = await axios.post(
      `${path}/boosts/energy/${decode(token)}`, 
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка при повышении уровня энергии', err);
    return null;
  }
};

export const upgradeMining = async (token) => {
  try {
    const response = await axios.post(
      `${path}/mining/upgrade_mining/${decode(token)}`, 
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка при повышении уровня майнинга', err);
    return null;
  }
};

export const claim = async (token) => {
  try {
    const response = await axios.post(
      `${path}/mining/claim/${decode(token)}`, 
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка при повышении уровня майнинга', err);
    return null;
  }
};