import Button from '../Button/Button';
import classes from './SmallTab.module.css'

const SmallTab = ({ img, titleTop, titleBot, subtitle, buttonContent, onClick, opacity }) => {
  return (
    <div className={classes.SmallTab} style={{opacity: opacity}}>
      <div className={classes.shadow}></div>

      <div className={classes.left}>
        <img src={img} alt={titleTop} className={classes.img}/>
        <div className={classes.description}>
          <p className={classes.title}>{titleTop} <span className={classes.gradient}>{titleTop}</span></p>
          <p className={classes.title}>{titleBot} <span className={classes.gradient}>{titleBot}</span></p>
          <p className={classes.subtitle}>{subtitle}</p>
        </div>
      </div>

      <div className={classes.right}>
        <Button
          blur={true}
          width={'66px'}
          height={'25px'}
          background={'#00FF88'}
          border={'10px'}
          font={'11px'}
          onClick={onClick}
        >
          {buttonContent}
        </Button>
      </div>

    </div>
  )
}

export default SmallTab;