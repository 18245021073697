import classes from './StatsTab.module.css'

const StatsTab = ({ title, stat }) => {
  return (
    <div className={classes.StatsTab}>
      <div className={classes.shadow}></div>
      <p className={classes.title}>{title}</p>
      <p className={classes.stat}>{stat} <span className={classes.gradient}>{stat}</span></p>
    </div>
  )
}

export default StatsTab;