import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTapSocket } from '../../../api/tapApi';
import classes from './Home.module.css';
import energyIcon from '../../../assets/svgs/energy-icon.svg';
import ClickBubble from '../../ui/ClickBubble/ClickBubble';
import uuid4 from "uuid4";
import { balancePlusOne, setUser, updateBalance, updateEnergy, updateMiningSecodns } from '../../../store/userSlice';
import { claim } from '../../../api/boosterApi';
import { formatNumberToM } from '../../../utils';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next'; // Импортируем useTranslation
import { getUser } from '../../../api/userApi';
import RewardModal from '../../modals/RewardModal/RewardModal';

const leagues = [
  'Bronze', 'Silver', 'Gold',
  'Platinum', 'Diamond', 'Saphyre',
  'Emerald', 'Ruby', 'Obsidian',
  'White gold',
];

//gitcheck
const Home = ({ sendTap, token, isLoading }) => {
  const { t } = useTranslation();
  const [texts, setTexts] = useState([]);
  const [touchCount, setTouchCount] = useState(0);
  const user = useSelector((state) => state.user);
  const isRewardOpen = useSelector((state) => state.reward.isOpen);
  const dispatch = useDispatch();
  const touchRef = useRef();
  const timeoutRef = useRef(null);
  const videoRef = useRef(null);
  const intervalRef = useRef(null);
  
  const [isPlaying, setIsPlaying] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
      videoRef.current.pause()
    }
  }, [videoRef, isLoading]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      getUser(token)
        .then((data) => dispatch(setUser(data)))
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [document.hidden]);

  useEffect(() => {
    const handleTouchMove = (e) => {
      e.preventDefault(); // Отключение скролла
    };
  
    document.addEventListener('touchmove', handleTouchMove, { passive: false });
  
    return () => {
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  useEffect(() => {
    touchRef.current = touchCount;
  }, [touchCount]);

  // Дебаунс функция для записи в localStorage
  const saveToLocalStorage = debounce(() => {
    localStorage.setItem('taps', JSON.stringify(touchRef.current));
  }, 500); // 500 мс задержка

  useEffect(() => {
    saveToLocalStorage(); // Вызываем дебаунс функцию при изменении touchCount

    // Чистим дебаунс функцию при размонтировании компонента
    return () => {
      saveToLocalStorage.cancel();
    };
  }, [touchCount, saveToLocalStorage]);

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current)
      clearTimeout(timeoutRef.current);
      if (touchRef.current > 0 && sendTap) {
        sendTap(touchRef.current);
        setTouchCount(0);
      }
    };
  }, []);

  const sendTapsAfterTimeout = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    
    timeoutRef.current = setTimeout(() => {
      if (touchRef.current > 0 && sendTap) {
        sendTap(touchRef.current);
        setTouchCount(0);
      }
    }, 1000);
  }, [sendTap]);

  const handleVideoPlayPause = useCallback(() => {
      if (!isPlaying) {
        videoRef.current.play();
        setIsPlaying(true);

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      const newTimeoutId = setTimeout(() => {
        if (videoRef.current) {
          intervalRef.current = setInterval(() => {
            if (videoRef.current.currentTime === 0) {
              clearInterval(intervalRef.current);
              videoRef.current.pause();
              videoRef.current.currentTime = 0;
              setIsPlaying(false);
            }
          }, videoRef.current.duration);
        }
      }, 1000);

      setTimeoutId(newTimeoutId);
    }
  }, [isPlaying, user.energy, timeoutId]);

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  const handleTouchEnd = useCallback((e) => {
    e.preventDefault();
    handleVideoPlayPause();
      const endTouches = Array.from(e.changedTouches).map((touch) => ({
        id: uuid4(),
        text: `+${user.tap_boost_level}`,
        x: touch.clientX,
        y: touch.clientY,
      }));

      setTexts((prevTexts) => [...prevTexts, ...endTouches]);

      const touchCounter = Array.from(e.changedTouches).length;
      const totalBoost = user.tap_boost_level * touchCounter;
      let currentBoost = 0;

      const updateBalance = () => {
        if (currentBoost < totalBoost) {
          dispatch(balancePlusOne());
          currentBoost++;
          // Запускаем следующее обновление без задержки
          setTimeout(updateBalance, 100);
        }
      };
      
      updateBalance();

      Array.from(e.changedTouches).forEach(() => {
        setTouchCount((prev) => prev + 1);
        sendTapsAfterTimeout();
        dispatch(updateEnergy({ energy: user.energy - user.tap_boost_level }));
      });
  }, [handleVideoPlayPause, sendTapsAfterTimeout, user]);

  const handleAnimationEnd = useCallback((id) => {
    setTexts((prevTexts) => prevTexts.filter((text) => text.id !== id));
  }, []);

  const claimHandler = async (e) => {
    e.stopPropagation();
    if (user.mining_level !== 0) {
      const response = await claim(token);
      if (response.status === 'success') {
        dispatch(updateBalance({ balance: response.new_balance }));
        dispatch(updateMiningSecodns({ mining_claim_seconds: response.mining_claim_seconds }));
      }
    }
  };

  const videoElement = useMemo(() => (
    <video
      ref={videoRef}
      className={classes.gekkoVid}
      controls={false}
      loop
      preload="auto"
      muted
      width="100%"
      height="auto"
      playsInline
      controlsList="nodownload nofullscreen noremoteplayback"
      style={{ pointerEvents: 'none' }}
      poster="/assets/poster_4.jpg"
    >
      <source src={'/assets/Gekko_4.mp4'} type="video/mp4" />
    </video>
  ), []);

  return (
    <div
      className={classes.Home}
      onTouchEnd={handleTouchEnd}
    >
      {isRewardOpen && <RewardModal/>}
      {texts.map((textObj) => (
        <ClickBubble
          key={textObj.id}
          text={textObj.text}
          x={textObj.x}
          y={textObj.y - 60}
          onAnimationEnd={() => handleAnimationEnd(textObj.id)}
        />
      ))}
      <div className={classes.shadow}></div>
      <img src={'/assets/back.svg'} alt='background' className={classes.background} />

      {videoElement}

      <div className={classes.container}>
        <div className={classes.sign_container}>
          <div onTouchEnd={(e) => e.stopPropagation()} className={classes.sign}>
            <p className={classes.title}>{t('home.league')}</p>
            <p className={classes.value}>{leagues[user.current_league - 1]}</p>
          </div>
          <div onTouchEnd={(e) => e.stopPropagation()} className={classes.sign}>
            <p className={classes.title}>{t('home.pph')}</p>
            <p className={classes.value}>{user.mining_level ? formatNumberToM((100 * 2**(user.mining_level - 1))) : '0'}</p>
          </div>
        </div>
        <div className={classes.main}>
          <h1 className={classes.score}>
            <img src={'/assets/coin.svg'} alt='coin' className={classes.coin} style={{width: '44px', height: '44px'}}/>
            <span className={classes.gradient}>{Math.floor(user.balance)}</span>
            <span className={classes.score_shadow}>{Math.floor(user.balance)}</span>
            {Math.floor(user.balance)}
          </h1>
          <div className={classes.claim}>
            <div onTouchEnd={(e) => e.stopPropagation()} onClick={claimHandler} className={classes.button} style={{ opacity: (user.mining_claim_seconds < 3600 || user.mining_level === 0) ? '50%' : '100%' }}>
              <p className={classes.claim_text}>{t('home.claim')}</p>
              <p className={classes.claim_text}>{user.mining_level ? formatNumberToM((user.mining_claim_seconds * (100 * 2**(user.mining_level - 1)) / 3600).toFixed(2)) : '0'}</p>
            </div>
            <div className={classes.energy}>
              <img src={energyIcon} alt='energy' />
              <p className={classes.energy_text}>{Math.floor(user.energy)}/{user.energy_boost_level}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
