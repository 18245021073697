import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { WebAppProvider } from '@vkruglikov/react-telegram-web-app';
import { auth, authorizeUser, getUser } from './api/userApi';
import { Provider } from 'react-redux';
import { store } from './store/store'
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import NoPhone from './components/pages/NoPhone/NoPhone';


const tg = window.Telegram ? window.Telegram.WebApp : null;
const platform = window.Telegram.WebApp.platform;
console.log(platform)

const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  //True, если мобильное
};

const isTouchDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  //True, если поддерживает тапы
};

if (tg) {
  tg.expand();
  tg.ready();
  tg.isClosingConfirmationEnabled = true; // Включаем подтверждение перед закрытием
}


const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <WebAppProvider>
        <Provider store={store}>
          <I18nextProvider i18n={i18n}>
            { (platform === 'web' || platform === 'tdesktop') ?
              <NoPhone/>
              :
              <App/>
            }
          </I18nextProvider>
        </Provider>
      </WebAppProvider>
    </React.StrictMode>
);