import BigTab from '../../ui/BigTab/BigTab';
import DecorativeTab from '../../ui/DecorativeTab/DecorativeTab';
import classes from './Earn.module.css'
import daily from '../../../assets/svgs/dailyCalendar.svg'
import SmallTab from '../../ui/SmallTab/SmallTab';
import telegram from '../../../assets/svgs/telegram-icon.svg'
import insta from '../../../assets/svgs/insta.png'
import tiktok from '../../../assets/svgs/tiktok.png'
import { useCallback, useDebugValue, useEffect, useMemo, useState } from 'react';
import DailyModal from '../../modals/DailyModal/DailyModal';
import ActionModal from '../../modals/ActionModal/ActionModal';
import { checkLinkTask, checkTask, getTasks, goLink, social_check, socialCheck } from '../../../api/taskApi';
import { setTasks, updateButtonState } from '../../../store/tasksSlice';
import { capitalizeFirstLetter } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { updateBalance } from '../../../store/userSlice';
import { getLink } from '../../../api/friendsApi';
import { setLink } from '../../../store/linkSlice';
import { useTranslation } from 'react-i18next';
import Social from '../Social/Social';

const Earn = ({ token }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState(<DailyModal onClose={() => setModalOpen(false)}/>)
  const dispatch = useDispatch()
  const { loadedTasks, dailyTasks, specialTasks, buttonsState } = useSelector((state) => state.tasks);
  const user = useSelector((state) => state.user);
  const link = useSelector((state) => state.link.link);
  const [isSocialOpen, setIsSocialOpen] = useState(false);
  const [socialTasks, setSocialTask] = useState([])
  const [socialContent, setSocialContent] = useState(<Social/>)

  const fetchLink = useCallback(async () => {
    console.log(link)
    if (link === '') {
      const response = await getLink(token);
      const invite_link = response.invite_link;
      dispatch(setLink(invite_link));
    }
  }, [token, link]);

  useEffect(() => {
    fetchLink();
  }, [fetchLink]);

  const fetchTasks = useCallback(async () => {
    if (!loadedTasks.length) {
      console.log(loadedTasks)
      const response = await getTasks(token)
      dispatch(setTasks(response))
    }
  }, [token, loadedTasks])

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks])

  const showDaily = () => {
    setModalContent(<DailyModal onClose={() => setModalOpen(false)}/>)
    setModalOpen(true)
  }

  const showAction = (token, img, titleTop, titleBot, id) => {
    setModalContent(
      <ActionModal
        token={token} 
        onClose={() => setModalOpen(false)} 
        img={img} 
        titleTop={titleTop} 
        titleBot={titleBot} 
        id={id}/>)
    setModalOpen(true)
  }

  const showSocialAction = (token, img, titleTop, titleBot, id) => {
    setModalContent(
      <ActionModal
        token={token} 
        onClose={() => setModalOpen(false)} 
        img={img} 
        titleTop={titleTop} 
        titleBot={titleBot} 
        id={id}
        buttonContent={'Back'}
        onClick={() => setModalOpen(false)}
        />)
    setModalOpen(true)
  }

  const onLinkButtonClick = async(id, token) => {
    const currentState = buttonsState.find(item => item.id === id); // Находим состояние кнопки по id
    const currentTask = loadedTasks.find(item => item.id === id); // Находим задачу по id
    const tg = window.Telegram ? window.Telegram.WebApp : null;
    if (currentState?.status === 'not_completed') {
      // Открываем ссылку, если задача ещё не началась
      tg.openLink(currentTask.link);
      dispatch(updateButtonState({ id: id, status: 'ready_to_claim'}));
    } else if (currentState?.status === 'ready_to_claim') {
      const response = await checkLinkTask(id, token)
      dispatch(updateBalance({ balance: Math.floor(response[0].new_balance)}))
      dispatch(updateButtonState({ id: id, status: 'completed'}));
    }
  }

  const onInstButtonClick = async(id, token) => {
    const currentState = buttonsState.find(item => item.id === id); // Находим состояние кнопки по id
    const currentTask = loadedTasks.find(item => item.id === id); // Находим задачу по id
    const tg = window.Telegram ? window.Telegram.WebApp : null;
    if (currentState?.status === 'not_completed') {
      // Открываем ссылку, если задача ещё не началась
      tg.openLink(currentTask.link);
      await goLink(id, token);
      dispatch(updateButtonState({ id: id, status: 'in_progress'}));
    } else if (currentState?.status === 'in_progress') {
      const response = await socialCheck(id, token)
      if (response.status === 'in_progress') {
        showSocialAction(token, currentTask.task_type === 'insta' ? insta : currentTask.task_type === 'tiktok' ? tiktok : telegram, t('earn.tur'), t('earn.pcbl'), id)
      } else {
        console.log(response.status)
        dispatch(updateBalance({ balance: Math.floor(response.new_balance)}))
        dispatch(updateButtonState({ id: id, status: 'completed'}));
      }
    }
  }

  const onTiktokButtonClick = async(id, token) => {
    const currentState = buttonsState.find(item => item.id === id); // Находим состояние кнопки по id
    const currentTask = loadedTasks.find(item => item.id === id); // Находим задачу по id
    const tg = window.Telegram ? window.Telegram.WebApp : null;
    if (currentState?.status === 'not_completed') {
      // Открываем ссылку, если задача ещё не началась
      tg.openLink(currentTask.link);
      await goLink(id, token);
      dispatch(updateButtonState({ id: id, status: 'in_progress'}));
    } else if (currentState?.status === 'in_progress') {
      const response = await socialCheck(id, token)
      if (response.status === 'in_progress') {
        showSocialAction(token, currentTask.task_type === 'insta' ? insta : currentTask.task_type === 'tiktok' ? tiktok : telegram, t('earn.tur'), t('earn.pcbl'), id)
      } else {
        dispatch(updateBalance({ balance: Math.floor(response.new_balance)}))
        dispatch(updateButtonState({ id: id, status: 'completed'}));
      }
    }
  }

  const onStoryButtonClick = async(id, token) => {
    const currentState = buttonsState.find(item => item.id === id); // Находим состояние кнопки по id
    const currentTask = loadedTasks.find(item => item.id === id); // Находим задачу по id
    const tg = window.Telegram ? window.Telegram.WebApp : null;
    if (currentState?.status === 'not_completed') {
      // Открываем ссылку, если задача ещё не началась
      tg.shareToStory(currentTask.link, {text: `${currentTask.description}\n ${link}`})
      await goLink(id, token);
      dispatch(updateButtonState({ id: id, status: 'in_progress'}));
    } else if (currentState?.status === 'in_progress') {
      const response = await socialCheck(id, token)
      if (response.status === 'in_progress') {
        showSocialAction(token, currentTask.task_type === 'insta' ? insta : currentTask.task_type === 'tiktok' ? tiktok : telegram, t('earn.tur'), t('earn.pcbl'), id)
      } else {
        dispatch(updateBalance({ balance: Math.floor(response.new_balance)}))
        dispatch(updateButtonState({ id: id, status: 'completed'}));
      }
    }
  }

  const onSubButtonClick = async (id, token) => {
    const currentState = buttonsState.find(item => item.id === id); // Находим состояние кнопки по id
    const currentTask = loadedTasks.find(item => item.id === id); // Находим задачу по id
    const tg = window.Telegram ? window.Telegram.WebApp : null;
  
    if (currentState?.status === 'not_completed') {
      // Открываем ссылку, если задача ещё не началась
      tg.openTelegramLink(currentTask.link);
      dispatch(updateButtonState({ id: id, status: 'started' })); // Обновляем статус на 'started'
    } else if (currentState?.status === 'started') {
      // Проверяем задачу, если она в статусе 'started'
      const response = await checkTask(id, token);
      if (response[0].status === 'success') {
        dispatch(updateButtonState({ id: id, status: 'ready_to_claim' })); // Обновляем статус на 'ready_to_claim'
      } else {
        
        showAction(token, telegram, currentTask.title, currentTask.description, id)
      }
    } else if (currentState?.status === 'ready_to_claim') {
      dispatch(updateBalance({ balance: +user.balance + +Math.floor(currentTask.reward)}))
      dispatch(updateButtonState({ id: id, status: 'completed' }));
    } else if (currentState?.status === 'completed') {
      console.log('claimed'); // Если задача уже выполнена
    }
  };

  const taskClickHandler = (id, token) => {
    const currentTask = loadedTasks.find(item => item.id === id);
    switch (currentTask?.task_type) {
      case 'subscribe':
        onSubButtonClick(id, token)
        break;

      case 'link':
        onLinkButtonClick(id, token)
        break;

      case 'insta':
        onInstButtonClick(id, token)
        break;

      case 'tiktok':
        onTiktokButtonClick(id, token)
        break;
    
      case 'story':
        onStoryButtonClick(id, token)
        break;
      default:
        break;
    }
  }
  
  const subButtonContent = (id) => {
    const currentState = buttonsState.find(item => item.id === id); // Находим текущее состояние кнопки
  
    // Возвращаем текст для кнопки в зависимости от состояния
    switch (currentState?.status) {
      case 'not_completed':
        return t('earn.start');
      case 'in_progress':
        return t('earn.check');
      case 'started':
        return t('earn.check');
      case 'ready_to_claim':
        return t('earn.claim');
      case 'completed':
        return t('earn.done');
      default:
        return 'Unknown';
    }
  };
  
  const memoizedTasks = useMemo(() => (
    [...dailyTasks]
    .filter(task => buttonsState.find(item => item.id === task.id)?.status !== 'completed') // Фильтрация по статусу
    .sort((a, b) => b.reward - a.reward)
    .map((task) => (
      <SmallTab
        key={task.id}
        img={task.task_type === 'insta' ? insta : task.task_type === 'tiktok' ? tiktok : telegram}
        titleTop={task.title}
        titleBot={task.task_type === 'story' ? " " : task.description}
        subtitle={task.reward}
        buttonContent={subButtonContent(task.id)} // Текст кнопки в зависимости от состояния задачи
        onClick={() => taskClickHandler(task.id, token)} // Обработчик клика для кнопки
        opacity={buttonsState.find(item => item.id === task.id)?.status === 'completed' ? '60%' : '100%'} // Если задача завершена, делаем прозрачность 60%
      />
    ))
  ), [dailyTasks, buttonsState]);

  const memoizedSpecialTasks = useMemo(() => (
    [...specialTasks]
    .filter(task => buttonsState.find(item => item.id === task.id)?.status !== 'completed') // Фильтрация по статусу
    .sort((a, b) => b.reward - a.reward)
    .map((task) => (
      <SmallTab
        key={task.id}
        img={task.task_type === 'insta' ? insta : task.task_type === 'tiktok' ? tiktok : telegram}
        titleTop={task.title}
        titleBot={task.description}
        subtitle={task.reward}
        buttonContent={subButtonContent(task.id)} // Текст кнопки в зависимости от состояния задачи
        onClick={buttonsState.find(item => item.id === task.id)?.status === 'completed' ? () => {} : () => openSocial(task.id, token, task.title, task.description)} // Обработчик клика для кнопки
        opacity={buttonsState.find(item => item.id === task.id)?.status === 'completed' ? '60%' : '100%'} // Если задача завершена, делаем прозрачность 60%
      />
    ))
  ), [specialTasks, buttonsState]);

  const openSocial = (id, token, titleTop, titleBot) => {
    const currentTask = loadedTasks.find(item => item.id === id);
    const subIdsArray = currentTask.link.match(/\d+/g).map(Number);
    const subTasks = loadedTasks.filter(task => subIdsArray.includes(task.id))
    setSocialContent(<Social close={() => setIsSocialOpen(false)} id={id} token={token} tasks={subTasks} titleTop={titleTop} titleBot={titleBot}/>)
    setIsSocialOpen(true)
  }

  return (
    <div className={classes.Earn}>
      {modalOpen && modalContent}
      {isSocialOpen && socialContent}
      <div className={classes.container}>
        <div className={classes.relative}>


        <DecorativeTab 
          width={'65px'} 
          height={'30px'} 
          color={'#00FF88'} 
          bgcolor={'rgba(0, 255, 136, 0.20)'}
          radius={'12px'}
          font={'15px'}
          position={{top: '-30px', left: '-65px'}}
          main={true}
        >
          <span className={classes.plus}>+</span>500
        </DecorativeTab>
        <DecorativeTab 
          width={'55px'} 
          height={'26px'} 
          color={'#00FF88'} 
          bgcolor={'rgba(0, 255, 136, 0.20)'}
          radius={'10px'}
          font={'13px'}
          position={{bottom: '-26px', left: '-30px'}}
          main={true}
        >
          <span className={classes.plus}>+</span>500
        </DecorativeTab>
        <DecorativeTab 
          width={'88px'} 
          height={'41px'} 
          color={'#00FF88'} 
          bgcolor={'rgba(0, 255, 136, 0.20)'}
          radius={'16px'}
          font={'21px'}
          position={{top: '-31px', right: '-75px'}}
          main={true}
        >
          <span className={classes.plus}>+</span>500
        </DecorativeTab>


          <h1 className={classes.title}>
            <span className={classes.gradient}>{t('earn.ct')}<br/>{t('earn.em')}</span>
            <span className={classes.shadow}>{t('earn.ct')}<br/>{t('earn.em')}</span>
            {t('earn.ct')}<br/>{t('earn.em')}
          </h1>
        </div>

        <div className={classes.tasks}>
        <div>
          <BigTab
            img={daily}
            title={t('earn.dr')}
            subtitle={'+1 000 000'}
            onClick={showDaily}
          />
        </div>
        <div className={classes.task}>
          <p className={classes.taskTitle}>{t('earn.st')}</p>
          {/*
          <SmallTab
            img={telegram}
            titleTop={t('earn.soo')}
            titleBot={t('earn.s')}
            subtitle={''}
            buttonContent={t('earn.go')}
            onClick={showSocial}
          />
          */}
          {memoizedSpecialTasks}
        </div>
        <div className={classes.task}>
          <p className={classes.taskTitle}>{t('earn.dt')}</p>
          {memoizedTasks}
        </div>
        
        </div>
      </div>
    </div>
  )
}

export default Earn;