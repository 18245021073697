import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import Loading from './components/pages/Loading/Loading'
import Home from './components/pages/Home/Home';
import Menu from './components/ui/Menu/Menu';
import League from './components/pages/League/League';
import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, updateBalance, updateEnergy, updateMiningSecodns, updateStreak } from './store/userSlice';
import { getToken, getUser } from './api/userApi';
import { useTapSocket } from './api/tapApi';
import Onboarding from './components/modals/Onboarding/Onboarding';
import { dailyReward } from './api/taskApi';
import { useTranslation } from 'react-i18next';
import { toggleReward } from './store/rewardSlice';

function App() {
  const dispatch = useDispatch()
  const WebApp = useWebApp();
  const [onboadingContent, setOnboardingContent] = useState(0)
  const [loading, setLoading] = useState(true)
  const [fetching, setFetching] = useState(true)
  const [token, setToken] = useState()
  const { message, sendTap } = useTapSocket((token))
  const [activeTab, setActiveTab] = useState(<Home sendTap={sendTap}/>)
  const [activeIndex, setActiveIndex] = useState(0);
  const user = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  
  useEffect(() => {
    if (user.mining_level) {
      const interval = setInterval(() => {
        if (user.mining_claim_seconds < 28800) {
          dispatch(updateMiningSecodns({ mining_claim_seconds: user.mining_claim_seconds + 0.1 }));
        }
      }, 100);
  
      return () => clearInterval(interval);
    }
  }, [user.mining_level, user.mining_claim_seconds, dispatch]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
      setOnboardingContent(1)
    }, 3000)
  }, [])

  useEffect(() => {
    const fetch = async () => {
      const response = await getToken()
      const token = response.access_token
      setToken(token)
      setActiveTab(<Home sendTap={sendTap} token={token} isLoading={loading}/>)
      await getUser(token)
        .then((data) => dispatch(setUser(data)))
        .finally(() => setFetching(false))
      await dailyReward(token)
        .then((data) => {
          dispatch(updateBalance({balance: Number(data.balance)}));
          dispatch(updateStreak({login_streak: data.login_streak}));
          if (data.rewarded && user.is_new_user === false) {
            dispatch(toggleReward())
          }
        })
    }
    
    fetch()
  }, [])

  useEffect(() => {
    if (user.energy < user.energy_boost_level) {
      const interval = setInterval(() => {
        const newEnergy = user.energy + 3;
        if (newEnergy <= user.energy_boost_level) {
          dispatch(updateEnergy({ energy: newEnergy }));
        } else {
          dispatch(updateEnergy({ energy: user.energy_boost_level }));
          clearInterval(interval);
        }
      }, 1000);
  
      return () => clearInterval(interval);
    }
  }, [dispatch, user.energy, user.energy_boost_level]);
  
  const onboardingComplete = () => {
    if (onboadingContent >= 6) {
      setActiveTab(<Home sendTap={sendTap} token={token} isLoading={loading}/>)
    }
  }

  return (
    <>
    {(user.is_new_user && onboadingContent < 7) &&
      <Onboarding token={token} onEnd={onboardingComplete} setActiveTab={setActiveTab} setActiveIndex={setActiveIndex} content={onboadingContent} setContent={setOnboardingContent}/>
    }

    {(loading === true || (user.is_new_user && onboadingContent < 2) || fetching === false) &&
    <Loading newUser={user.is_new_user}/>
    }
    {activeTab}
    <Menu sendTap={sendTap} token={token} setActiveTab={setActiveTab} activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>
    </>
  );
}

export default App;
