import axios from "axios";
import { decode } from "./userApi";
import { path } from "./path";

export const checkTask = async (task, token) => {
  try {
    const response = await axios.post(
      `${path}/tasks/check/${task}/${decode(token)}`, 
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка проверки задания', err);
    return null;
  }
};

export const checkLinkTask = async (task, token) => {
  try {
    const response = await axios.post(
      `${path}/tasks/complete_link/${task}/${decode(token)}`, 
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка проверки задания', err);
    return null;
  }
};

export const getTasks = async (token) => {
  try {
    const response = await axios.get(
      `${path}/tasks/active/${decode(token)}`, 
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка получения заданий', err);
    return null;
  }
};

export const dailyReward = async (token) => {
  try {
    const response = await axios.post(
      `${path}/users/daily_reward/${decode(token)}`, 
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка проверки задания', err);
    return null;
  }
};

export const goLink = async (task, token) => {
  try {
    const response = await axios.post(
      `${path}/tasks/go_link/${task}/${decode(token)}`, 
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка проверки задания', err);
    return null;
  }
};

export const socialCheck = async (task, token) => {
  try {
    const response = await axios.get(
      `${path}/tasks/social_check/${task}/${decode(token)}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка проверки задания', err);
    return null;
  }
};

export const checkSpecTask = async (task, token) => {
  try {
    const response = await axios.post(
      `${path}/tasks/check/spec/${task}/${decode(token)}`, 
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка проверки задания', err);
    return null;
  }
};